<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" colspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" colspan="17">
            <h1>
              Qualifications & Certificates of Sinopec Nanjing Engineering
              Middle East Co., Ltd 中石化南京工程中东公司企业资质情况
            </h1>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            序号 <br />
            SN
          </td>
          <td class="text-center">
            Traade Name<br />
            公司名称
          </td>
          <td class="text-center">
            NO. of Establishment<br />
            公司代码
          </td>
          <td class="text-center">
            Government System<br />
            政府系统平台
          </td>
          <td class="text-center">
            Annual Fee of Gov. System<br />
            系统年费
          </td>
          <td class="text-center">
            Name of Related Employee<br />
            被注册人员姓名
          </td>
          <td class="text-center">
            IQAMA/ID NO. of Related Employee<br />
            被注册人员暂住证号
          </td>
          <td class="text-center">
            Mobile NO. of Related Employee<br />
            被注册人员电话
          </td>
          <td class="text-center">
            Emial of Related Employee <br />被注册人员邮箱
          </td>
          <td class="text-center">
            Description of Fuction Gov. System<br />
            系统用途
          </td>
          <td class="text-center">
            Certificate Issued by Gov. System <br />
            系统签发资质
          </td>
          <td class="text-center">
            Annual Fee of Certificate<br />
            资质年费
          </td>
          <td class="text-center">
            Certificate NO <br />
            证书号
          </td>
          <td class="text-center">
            Date of Issue (Hijri)<br />
            签发日期（伊）
          </td>
          <td class="text-center">
            Date of Issue (Gregorian)<br />
            签发日期（公历）
          </td>
          <td class="text-center">
            Valid Date (Hijri)<br />
            有效日期（伊）
          </td>
          <td class="text-center">
            Valid Date (Gregorian)<br />
            有效日期（公历）
          </td>
          <td class="text-center">
            Remark<br />
            备注
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">
            <el-select
              v-model="item.TradeID"
              @change="changeTradeID(index)"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in TradeList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            {{ item.CompanyCode }}
          </td>
          <td class="text-center">
            <el-select
              v-model="item.GovernmentSystemID"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in GovernmentList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.AnnualFeeofGovSystem"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.NameofRelatedEmployee"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.IQAMAofRelatedEmployee"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.MobileCodeofRelatedEmployee"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.EmailofRelatedEmployee"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.DescriptionofFuctionGovSystem"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input v-model="item.CertificateName" placeholder="请输入" />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.AnnualFeeofCertificate"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input v-model="item.CertificateCode" placeholder="请输入" />
          </td>
          <td class="text-center">
            <el-input
              v-model="item.IslamicDateofIssue"
              placeholder="yyyy/MM/dd"
              @input="changeIslamicDateofIssue(index)"
            />
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="item.DateofIssue"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.IslamicValidityDate"
              @input="changeIslamicValidityDate(index)"
              placeholder="yyyy/MM/dd"
            />
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="item.ValidityDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input v-model="item.Remark" placeholder="备注" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  addOrgQualification,
  updateOrgQualification,
  getGovernmentSystemDicAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      CostAllocationDetails: [],
      TradeList: [],
      GovernmentList: [],
      costAllocationItem: {
        ID: null,
        TradeID: null,
        GovernmentSystemID: null,
        AnnualFeeofGovSystem: null,
        NameofRelatedEmployee: null,
        IQAMAofRelatedEmployee: null,
        MobileCodeofRelatedEmployee: null,
        EmailofRelatedEmployee: null,
        DescriptionofFuctionGovSystem: null,
        CertificateName: null,
        AnnualFeeofCertificate: null,
        CompanyCode: null,
        CertificateCode: null,
        DateofIssue: null,
        IslamicDateofIssue: null,
        ValidityDate: null,
        IslamicValidityDate: null,
        Remark: null,
        Url: null,
      },
    };
  },

  methods: {
    addCostAllocation(type) {
      const data = [];
      this.CostAllocationDetails.forEach((item) => {
        if (item.TradeID) {
          data.push(JSON.parse(JSON.stringify(item)));
        }
      });
      addOrgQualification({ list: data }).then((res) => {
        if (res.status === 200) {
          this.$message.success("新建成功");
          this.$emit("update");
          this.$emit("close");
        }
      });
    },
    geteditData(params) {
      console.log("params", params);
      this.CostAllocationDetails[0] = JSON.parse(JSON.stringify(params));
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    //修改签发伊斯兰日期
    changeIslamicDateofIssue(index) {
      let date = this.CostAllocationDetails[index].IslamicDateofIssue;

      if (date.length > 4) {
        let isTrue1 = this.isCharEqual(date, 5, "/");
        if (!isTrue1) {
          date = this.replaceAt(date, 4, "/");
        }
      }
      if (date.length > 7) {
        let isTrue1 = this.isCharEqual(date, 8, "/");
        if (!isTrue1) {
          date = this.replaceAt(date, 7, "/");
        }
      }
      if (date.length > 10) {
        date = date.slice(0, 10);
      }
      this.CostAllocationDetails[index].IslamicDateofIssue = date;
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },

    //修改签发伊斯兰有效期
    changeIslamicValidityDate(index) {
      let date = this.CostAllocationDetails[index].IslamicValidityDate;
      if (date.length > 4) {
        let isTrue1 = this.isCharEqual(date, 5, "/");
        if (!isTrue1) {
          date = this.replaceAt(date, 4, "/");
        }
      }
      if (date.length > 7) {
        let isTrue1 = this.isCharEqual(date, 8, "/");
        if (!isTrue1) {
          date = this.replaceAt(date, 7, "/");
        }
      }
      if (date.length > 10) {
        date = date.slice(0, 10);
      }
      this.CostAllocationDetails[index].IslamicValidityDate = date;
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },

    //修改字符串
    replaceAt(str, index, replacement) {
      return str.slice(0, index) + replacement + str.slice(index + 1);
    },

    isCharEqual(str, index, charToCompare) {
      // 检查索引是否在字符串长度范围内
      if (index >= 0 && index < str.length) {
        // 获取索引处的字符并比较
        return str.charAt(index) === charToCompare;
      }
      // 如果索引超出字符串范围，返回false
      return false;
    },

    changeTradeID(index) {
      const TradeID = this.CostAllocationDetails[index].TradeID;
      const item = this.TradeList.find((item) => {
        return item.ID === TradeID;
      });
      if (item) {
        this.CostAllocationDetails[index].CompanyCode = item.Code;
      }

      if (
        index === this.CostAllocationDetails.length - 1 &&
        this.CostAllocationDetails.length < 100
      ) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }

    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.TradeList = res.response;
      }
    });
    getGovernmentSystemDicAll({ typeID: 2 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
    padding: 5px;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>